import { Box } from '@mui/material';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import {
  useLogoutMutation,
} from '../../../plugins/gatsby-plugin-redux/store/api/auth.api';
import useIsLoggedIn from '../../../plugins/gatsby-plugin-session-check/hooks/useIsLoggedIn';
import Application from '../../components/app/Application';
import CircularProgress from '../../components/app/CircularProgress';
import Content from '../../components/app/Content';

const Logout = () => {
  const isLoggedIn = useIsLoggedIn();
  const [logoutAction] = useLogoutMutation();

  useEffect(() => {
    if (!isLoggedIn) {
      void navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      void logoutAction().then(() => {
        void navigate('/');
      });
    }
  }, [isLoggedIn, logoutAction]);

  return (
    <Application>
      <Content>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <CircularProgress center />
        </Box>
      </Content>
    </Application>
  );
};

export default Logout;
